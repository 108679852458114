// api url logic
let prodAPI = 'https://api.chorus-queue.com'
let devAPI = 'http://127.0.0.1:5000'
var apiURL: string

var prodCallback = 'https://www.chorus-queue.com/dashboard'
var devCallback = 'http://localhost:3000/callback'
var callbackURL: string
// change to false if in development
let isProd = true

if (isProd) {
    apiURL = prodAPI
    callbackURL = prodCallback
} else {
    apiURL = devAPI
    callbackURL = devCallback
}

export {apiURL, callbackURL}