import React from 'react';
import ReactDOM from "react-dom";
import './index.css';
import Layout from "./pages/Layout";
import Home from "./pages/Home";
import About from "./pages/About";
import Songs from "./pages/Songs";
import Queues from "./pages/Queues";
import Cities from "./pages/Cities";
import NoPage from "./pages/NoPage";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import MenuBar from "./components/menubar";
import Login from "./pages/Login";
import Callback from "./pages/Callback";
import Dashboard from "./pages/Dashboard";
import Instance from "./pages/Instance";
import Search from './pages/Search';
import DeprecatedDashboardComponent from './components/deprecated_dashboardcomponent';
import Visualizations from './pages/Visualizations';
import ProviderVisualizations from './pages/ProviderVisualizations';



function App() {
  return (
    <div>
      <Home />
    </div>
  );
}

export default App;
