import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import welcome from '../images/queueGraphics/carousel1.jpg'
import second from '../images/queueGraphics/carousel2.jpg'
import explore from '../images/queueGraphics/carousel3.jpg'
import filler from '../images/queueGraphics/scrollGraphic.jpg'
import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";

function Home(){
    return (
      <>
      <b>Welcome to Queue</b>
      <br></br>
      <br></br>

      <b>exciting news coming soon!</b>
      </>
    );
  }

export default Home;